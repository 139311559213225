import React, { Component, Fragment } from 'react';
// import './PersonalDetails.scss';

import { Row, Col, Button } from 'reactstrap';
import Images from "../../../../components/images";
import VerifyDocument from '../../VerifyDocument/VerifyDocument';

import * as NC from "../../../../helper/NetworkingConstants";
import WSManager from "../../../../helper/WSManager";
import _ from 'lodash';
import AddNotesPopup from '../AddNotes/AddNotes';
import Moment from 'react-moment';
class PersonalDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userDetail: [],
            isnoteModalOpen: false,
        }
    }


    componentDidMount() {
        this.getUserDetail();
        this.getNotes();       
    }
    getUserDetail = () => {
        this.setState({ posting: true })
        let params = { "user_unique_id": this.props.user_unique_id };
        WSManager.Rest(NC.baseURL + NC.GET_USER_DETAIL, params).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let userDetail = responseJson.data;
                this.setState({ userDetail: userDetail }, () => {
                    if (this.props.scroll == true)
                    {
                        setTimeout(() => {
                            let element = document.getElementById('kycID')
                            element.scrollIntoView({ behavior: 'smooth' })
                        }, 100);
                    }                    
                })
                this.setState({ posting: false })
            }
            this.setState({ posting: false })
        })
    }
    getNotes = () => {
        this.setState({ posting: true })
        let params = { 'user_unique_id': this.props.user_unique_id };
        WSManager.Rest(NC.baseURL + NC.GET_NOTES, params).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({ notes: responseJson.data, posting: false })
            }
            this.setState({ posting: false })

        });
    }

    noteModal = () => {
        this.setState({
            isnoteModalOpen: !this.state.isnoteModalOpen
        }, () => {
            if (!this.state.isnoteModalOpen)
                this.getNotes()
        });
    }

    render() {
        const { isnoteModalOpen, userDetail } = this.state
        const NoteModal_props = {
            isnoteModalOpen: isnoteModalOpen,
            modalCallback: this.noteModal,
            user_unique_id: this.props.user_unique_id
        }
        return (
            <Fragment>
                <div className="personal-box">
                    <div className="box-heading">Account Verification</div>
                    <Row className="verification-box">
                        <Col md={3}><span className="verify-item">Phone
                    <img src={(this.state.userDetail.phone_verfied == 1) ? Images.VERIFIED : Images.DEFAULT_CIRCLE} alt="" />
                        </span></Col>
                        <Col md={3}><span className="verify-item">Email
                    <img src={(this.state.userDetail.email_verified == 1) ? Images.VERIFIED : Images.VERIFIED} alt="" />
                        </span></Col>
                        <Col md={3}><span className="verify-item">PAN
                    <img src={(this.state.userDetail.pan_verified == 1) ? Images.VERIFIED : Images.DEFAULT_CIRCLE} alt="" />
                        </span></Col>
                        <Col md={3}><span className="verify-item">Bank
                    <img src={(this.state.userDetail.is_bank_verified == 1) ? Images.VERIFIED : Images.DEFAULT_CIRCLE} alt="" />
                        </span></Col>
                    </Row>
                    <div className="box-heading m-t-30">Basic Details</div>
                    <div className="details-box">
                        <Row className="box-items">
                            <Col md={3}>
                                <label>Registration</label>
                                <div className="user-value">
                                    <Moment date={WSManager.getUtcToLocal(userDetail.member_since)} format="D MMMM YY" />
                                </div>
                            </Col>
                            <Col md={3}>
                                <label>Referred By</label>
                                <div className="user-value text-ellipsis">
                                {(this.state.userDetail.referee) ? this.state.userDetail.referee.full_name : '--'}
                                </div></Col>
                            {/* <Col md={3}>
                                <label>Source</label>
                                <div>
                                    <img className="mr-2" src={Images.WATSAPP_CIRCLE} alt="" />
                                    <img className="mr-2" src={Images.FACEBOOK_CIRCLE} alt="" />
                                    <img src={Images.INSTA_CIRCLE} alt="" />
                                </div>
                            </Col> */}
                            <Col md={3}>
                                <label>Last Logged in</label>
                                <div className="user-value">{(this.state.userDetail.last_login_date) ? this.state.userDetail.last_login_date : '--'} </div>
                            </Col>
                        </Row>
                        {/* <Row className="box-items m-t-30">
                            <Col md={3}>
                                <label>Last Active</label>
                                <div className="user-value">{(this.state.userDetail.last_active_date) ? this.state.userDetail.last_active_date : '--'}</div>
                            </Col>
                            <Col md={3}>
                                <label>Total Time Spent</label>
                                <div className="user-value">--</div></Col>
                            <Col md={3}>
                                <label>Avg Session Time</label>
                                <div className="user-value">--</div>
                            </Col>
                            <Col md={3}>
                            </Col>
                        </Row> */}
                    </div>
                    <div id="kycID" className="box-heading m-t-30">KYC</div>
                    <div className="details-box">
                        <Row className="box-items">
                            <Col md={3}>
                                <label>Phone</label>
                                <div className="user-value">{(this.state.userDetail.phone_no) ? this.state.userDetail.phone_no : '--'}</div>
                            </Col>
                            <Col md={3}>
                                <label>Email</label>
                                <div className="user-value">{(this.state.userDetail.email) ? this.state.userDetail.email : '--'}</div></Col>
                            {/* <Col md={3}>
                                <label>Date of Birth</label>
                                <div className="user-value">{(this.state.userDetail.dob) ? this.state.userDetail.dob : '--'}</div>
                            </Col> */}
                            {/* <Col md={3}>
                                <label>Gender</label>
                                <div className="user-value">{(this.state.userDetail.gender) ? this.state.userDetail.gender : '--'}</div>
                            </Col> */}
                        </Row>
                        {/* <Row className="box-items m-t-30">
                            <Col md={3}>
                                <label>Address</label>
                                <div className="user-value text-ellipsis">{(this.state.userDetail.address) ? this.state.userDetail.address : '--'} </div>
                            </Col>
                            <Col md={3}>
                                <label>City</label>
                                <div className="user-value text-ellipsis">{(this.state.userDetail.city) ? this.state.userDetail.city : '--'}</div></Col>
                            <Col md={3}>
                                <label>State</label>
                                <div className="user-value text-ellipsis">{(this.state.userDetail.state_name) ? this.state.userDetail.state_name : '--'}</div>
                            </Col>
                            <Col md={3}>
                            </Col>
                        </Row> */}

                        {/* { this.state.userDetail && this.state.userDetail.user_id && */}
                        <div>
                            {(userDetail.pan_no != null || userDetail.bank_document != null) && userDetail && userDetail.user_id &&
                                <VerifyDocument nameflag="0" userDetail={this.state.userDetail} />
                            }
                        </div>
                    </div>
                    <div className="notes-box">
                        <div>
                            <span className="notes-heading">Notes</span>
                            <span onClick={() => this.noteModal()} className="add-notes">Add Notes</span>
                        </div>
                        {this.state.notes &&
                            _.map(this.state.notes, (item, idx) => {
                                return (
                                    <div className="notes-container" key={idx}>
                                        <div className="notes-title">{item.subject}
                                            {item.is_flag ?
                                                <img className="flagged" src={Images.FLAG_ENABLE} alt="" />
                                                :
                                                <i className="icon-flag ml-2"></i>
                                            }
                                        </div>
                                        <div className="notes-date">
                                            <Moment date={WSManager.getUtcToLocal(item.create_date)} format="D MMMM YY" />
                                        </div>
                                        <div className="notes-desc">
                                            {item.note}

                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    {
                        isnoteModalOpen &&
                        <AddNotesPopup {...NoteModal_props} />
                    }
                </div>
            </Fragment>

        )
    }
}
export default PersonalDetails