import React, { Component, useState } from 'react';
import { Row, Col, Table, Tooltip, Button, TabContent, TabPane, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink } from 'reactstrap';
import Moment from 'react-moment';
import _ from 'lodash';
import * as NC from "../../helper/NetworkingConstants";
import WSManager from "../../helper/WSManager";
import Select from 'react-select';
import Images from '../../components/images';
import { notify } from 'react-notify-toast';
import MetaTagsServer from 'react-meta-tags/server';
import { MetaTagsContext } from 'react-meta-tags';
import Loader from '../../components/Loader';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import InfiniteScroll from 'react-infinite-scroller';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Referrals from '../UserManagement/Profile/Referrals/Referrals';
import PersonalDetails from '../UserManagement/Profile/PersonalDetails/PersonalDetails';

class UserDetails extends Component {

    constructor(props) {
        super(props);
        let filter = {
            current_page: 1,
            status: 1,
            pending_pan_approval: '',
            is_flag: '',
            keyword: '',
            items_perpage: 10,
            sort_field: 'added_date',
            sort_order: 'DESC',
        }
        this.state = {
            UserStatus: 1,
            total: 0,
            filter: filter,
            StartDate: '',
            EndDate: '',
            userData: null,
            tranactionlist: [],
            posting: false,
            activeTab: '1',
            PageScroll: false,
            userBasic:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount() {
        console.log(this.props,"222222222222")
        let userId = this.props.match.params.user_unique_id;
        console.log('props', userId);
        // if (this.props.location && this.props.location.state && this.props.location.state.data) {
        //     this.setState({ userData: this.props.location.state.data })
        // }
        // else {
        //     this.props.history.goBack(-1)
        // }
        let filter = this.state.filter;
        filter.user_unique_id = userId;
        this.setState({ filter })
        this.getUserDetails(userId);
        this.getUserBasic(userId)
    }

    getUserBasic = (user_unique_id) => {
        this.setState({ posting: true })
        let params = { "user_unique_id": user_unique_id };
        WSManager.Rest(NC.baseURL + NC.GET_USER_BASIC, params).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let userBasic = responseJson.data;
                this.setState({
                    userBasic: userBasic,
                    status: userBasic.status
                })
                this.setState({ posting: false })
            }
            this.setState({ posting: false })
        })
    }

    getUserDetails(userId) {
        this.setState({ posting: true })
        let params = {
            "user_unique_id": userId
        };
        WSManager.Rest(NC.baseURL + NC.GET_USERS_DETAILS, params).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({ posting: false, userData: responseJson.data })
            }
            else {
                this.setState({ posting: false })
            }
            this.getUserTransactions(userId);
        }).catch((error) => {
            this.setState({ posting: false })
            notify.show(NC.SYSTEM_ERROR, "error", 5000);
        })
    }

    getUserTransactions() {
        this.setState({ posting: true })
        // let params = {
        //         "user_unique_id": userId,
        //         "current_page": "1",
        //         "items_perpage": "10",
        //         "sort_field": "date_added",
        //         "sort_order": "DESC",
        //         "payment_method": ""
        //   };
        let params = this.state.filter;
        WSManager.Rest(NC.baseURL + NC.GET_TRANSACTION_LIST, params).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let total = responseJson.data.total;
                this.setState({ total: total, posting: false, tranactionlist: responseJson.data.result })
            }
            else {
                this.setState({ posting: false })
            }
        }).catch((error) => {
            this.setState({ posting: false })
            notify.show(NC.SYSTEM_ERROR, "error", 5000);
        })
    }

    handlePageChange(current_page) {

        let filter = this.state.filter;

        filter['current_page'] = current_page;

        this.setState(
            { filter: filter },
            function () {
                this.getUserTransactions();
            });
    }

    handleChange(date) {
        let filter = this.state.filter;
        filter['from_date'] = moment(date).format("YYYY-MM-DD");
        filter['current_page'] = 1;
        this.setState(
            {
                filter: filter,
                StartDate: date
            },
            function () {
                this.getUserTransactions();
            });


    }

    handleChangeEnd(date) {
        let filter = this.state.filter;
        filter['to_date'] = moment(date).format("YYYY-MM-DD");
        filter['current_page'] = 1;
        this.setState(
            {
                filter: filter,
                EndDate: date
            },
            function () {
                this.getUserTransactions();
            });
    }

    exportUser = () => {
        let { filter, StartDate, EndDate } = this.state
        let tempFromDate = ''
        let tempToDate = ''
        if (StartDate != '' && EndDate != '') {
            tempFromDate = moment(StartDate).format("YYYY-MM-DD");
            tempToDate = moment(EndDate).format("YYYY-MM-DD");
        }

        var query_string = 'status=' + filter.status + '&is_flag=' + filter.is_flag + '&items_perpage=' + filter.items_perpage + '&total_items=0&current_page=' + filter.current_page + '&sort_order=' + filter.sort_order + '&sort_field=' + filter.sort_field + '&country=&state=&keyword=' + filter.keyword + '&from_date=' + tempFromDate + '&to_date=' + tempToDate;


        let sessionKey = WSManager.getToken();
        query_string += "&Sessionkey" + "=" + sessionKey;

        window.open(NC.baseURL + 'adminapi/user/export_users?' + query_string, '_blank');
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                PageScroll: false
            });
        }
    }

    render() {
        let { tranactionlist, posting, filter, total, StartDate, EndDate, userData, activeTab, PageScroll, userBasic } = this.state;
        return (
            userData ?
                <div className='manageuserinfo-container'>
                    <div className='user-info-header'>
                        <div className='name'>{userData.user_name}</div>
                        <div className='email'>{userData.email ? '(' + userData.email + ')  ' : '-'}{userData.location ? userData.location : ''}</div>
                    </div>
                    <Row className="user-navigation only-for-un-cls">
                        <div className="w-100">
                            <Nav tabs>
                                <NavItem className={activeTab === '1' ? "active" : ""}
                                    onClick={() => { this.toggle('1'); }}>
                                    <NavLink>
                                        Dashboard
                                    </NavLink>
                                </NavItem>
                                <NavItem className={activeTab === '7' ? "active" : ""}
                                    onClick={() => { this.toggle('7'); }}>
                                    <NavLink>
                                        Referrals
                                    </NavLink>
                                </NavItem>
                                <NavItem className={activeTab === '2' ? "active" : ""}
                                onClick={() => { this.toggle('2'); }}>
                                <NavLink>
                                    Personal
                                </NavLink>
                            </NavItem>
                            </Nav>
                        </div>
                    </Row>
                    <div className='detail-tab-conent'>
                        <TabContent activeTab={activeTab}>
                            {
                                activeTab == '7' &&
                                <TabPane tabId="7">
                                    <Row>
                                        <Col sm="12">
                                        {userBasic.user_id != null &&
                                                <Referrals user_id={userBasic.user_id} />
                                            }
                                        </Col>
                                    </Row>
                                </TabPane>
                            }
                            {
                                activeTab == '1' &&
                                <TabPane tabId="1">
                                    <div className='profile-container'>

                                        <Row className='row col-md-12'>
                                            <Col className="col-md-4">
                                                <div className='label'>Email</div>
                                                <div className='value'>{userData.email}</div>
                                            </Col>
                                            <Col className="col-md-4">
                                                <div className='label'>Phone</div>
                                                <div className='value'>{userData.phone_no}</div>
                                            </Col>
                                            <Col className="col-md-4">
                                                <div className='label'>Location</div>
                                                <div className='value'>{userData.location ? userData.location : '-'}</div>
                                            </Col>
                                        </Row>
                                        <Row className='row col-md-12'>
                                            <Col className="col-md-4">
                                                <div className='label'>Subscription Start Date</div>
                                                <div className='value'>{userData.subscription_start_date ? moment(WSManager.getUtcToLocal(userData.subscription_start_date)).format("DD-MM-YYYY, hh:mm A") : '-'}</div>
                                            </Col>
                                            <Col className="col-md-4">
                                                <div className='label'>Subscription End Date</div>
                                                <div className='value'>{userData.subscription_end_date ? moment(WSManager.getUtcToLocal(userData.subscription_end_date)).format("DD-MM-YYYY, hh:mm A") : '-'}</div>
                                            </Col>
                                            <Col className="col-md-4">
                                                <div className='label'>Subscription Status</div>
                                                <div className='value'>{userData.subscription_status == 1 ? 'ACTIVE' : userData.subscription_status == 2 ? 'CANCELLED' : '-'}</div>
                                            </Col>

                                        </Row>
                                        <Row className='row col-md-12'>
                                            <Col className="col-md-4">
                                                <div className='label'>Payment Mode</div>
                                                <div className='value'>
                                                    {userData.payment_method == 2 ? 'Paytm' : userData.payment_method == 3 ? 'Android' : userData.payment_method == 4 ? 'IOS' : userData.payment_method == 5 ? 'Razorpay' : userData.payment_method == 1 ? 'CashFree' : '-'}
                                                </div>
                                            </Col>


                                        </Row>
                                    </div>


                                    <div className='manageuser-parent'>
                                        <div className="manage-user-heading clearfix">
                                            <h1 className="page-title">
                                                Transaction List</h1>

                                        </div>
                                        <Row className="filter-userlist">
                                            <Col md={9}>
                                                <div className="member-box float-left">
                                                    <div className="float-left">
                                                        <label className="filter-label">Start Date</label>
                                                        <DatePicker
                                                            maxDate={new Date()}
                                                            className="filter-date"
                                                            showYearDropdown='true'
                                                            selected={StartDate}
                                                            onChange={this.handleChange}
                                                            placeholderText="From"
                                                        />
                                                    </div>
                                                    <div className="float-left">
                                                        <label className="filter-label">End Date </label>
                                                        <DatePicker
                                                            maxDate={new Date()}
                                                            className="filter-date"
                                                            showYearDropdown='true'
                                                            selected={EndDate}
                                                            onChange={this.handleChangeEnd}
                                                            placeholderText="To"
                                                        />
                                                    </div>
                                                </div>

                                            </Col>
                                            {/* <Col md={3} className="mt-4">
<i className="export-list icon-export" onClick={e => this.exportUser()}></i>
</Col> */}
                                        </Row>
                                        <Row className="user-list">
                                            <Col className="md-12 table-responsive">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th className="left-th pl-4">Date</th>
                                                            <th>OrderId</th>
                                                            <th>Status</th>
                                                            <th className="pl-4">Transaction ID</th>
                                                            <th className="pl-2">Payment Mode</th>
                                                            <th className="pl-2">Reason</th>
                                                            <th className="right-th">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        tranactionlist.length > 0 ?
                                                            _.map(tranactionlist, (item, idx) => {
                                                                return (
                                                                    <tbody key={idx}>
                                                                        <tr>
                                                                            <td className="pl-4">{moment(WSManager.getUtcToLocal(item.date_added)).format("DD-MM-YYYY, hh:mm A")}</td>
                                                                            <td>{item.order_id}</td>
                                                                            <td>{item.status == '0' ? 'Pending' : item.status == '1' ? 'Complete' : item.status == '2' ? 'Failed' : '-'}</td>
                                                                            <td className="pl-4">{item.txn_id}</td>
                                                                            <td className="pl-2">{item.payment_method == 2 ? 'Paytm' : item.payment_method == 3 ? 'Android' : item.payment_method == 4 ? 'IOS' : item.payment_method == 1 ? 'CashFree' : '-'}</td>
                                                                            <td>{item.reason}</td>
                                                                            <td>{item.real_amount}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                )
                                                            })
                                                            :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="12">
                                                                        {(tranactionlist.length == 0 && !posting) ?
                                                                            <div className="no-records text-center">
                                                                                <br /><br /><br />
                                                                                No Transaction Found.
                                                                            </div>
                                                                            :
                                                                            <Loader />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                    }
                                                </Table>
                                                {
                                                    tranactionlist.length != 0 && this.state.total > filter.items_perpage &&
                                                    <div className="custom-pagination lobby-paging">
                                                        <Pagination
                                                            activePage={filter.current_page}
                                                            itemsCountPerPage={filter.items_perpage}
                                                            totalItemsCount={total}
                                                            pageRangeDisplayed={5}
                                                            onChange={e => this.handlePageChange(e)}
                                                        />
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                            }
                            {
                                (activeTab == '2') &&
                                <TabPane tabId="2">
                                    {
                                        (!_.isEmpty(this.props.match.params.user_unique_id) && !posting) &&
                                        <PersonalDetails scroll={PageScroll} user_unique_id={this.props.match.params.user_unique_id} />
                                    }
                                </TabPane>
                            }
                        </TabContent>

                    </div>

                </div>
                :
                <div className='manageuserinfo-container'>Data not availeble</div>
        )
    }
}
export default UserDetails;