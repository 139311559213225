var base_url="";
var node_base_url = "";
//Upload banner path
var SERVER_BANNER_PATH = "https://communication-dashboard.s3.amazonaws.com/upload/banner/";
var BANK_DOC_PATH = "";
var S3_SERVER_URL='';
switch (window.location.hostname) {
    
    case 'local.reactframework.com':
    case 'localhost':
        base_url = process.env.REACT_APP_BASE_URL;
        S3_SERVER_URL = process.env.REACT_APP_S3URL;
        break;
    default:
        base_url = process.env.REACT_APP_BASE_URL;
        S3_SERVER_URL = process.env.REACT_APP_S3URL;
        break;
}

//login 
export const DO_LOGIN = "adminapi/auth/dologin";
export const GET_FIXTURES = "adminapi/season/get_upcoming_sport_fixtures";
export const SAVE_EXPERT_ADVICE = "adminapi/expert_advice/save_expert_advice";
export const GET_EXPERT_ADVICE = "adminapi/expert_advice/get_fixture_expert_advice";
export const DELETE_EXPERT_ADVICE = "adminapi/expert_advice/delete_advice_by_id";
export const UPDATE_EXPERT_ADVICE = "adminapi/expert_advice/update_advice_by_id";
export const GET_EXPERT_ADVICE_BY_ID = "adminapi/expert_advice/get_advice_by_id";
export const GET_META_FROM_URL = "adminapi/expert_advice/get_url_metadata";
export const UPDATE_LINEUPOUT_NOTIFICATION = "adminapi/season/update_lineupout_notification";
export const GET_FIXTURES_BY_STATUS = "adminapi/season/get_fixture_list";
export const SET_SIMULATOR_CONTEST = "adminapi/season/set_simulator_contest";
export const UPLOAD_RESULT_PDF = "adminapi/season/upload_result_pdf";
export const GET_SIMULATOR_CONTEST_BYID = "adminapi/season/simulator_contest_by_id";
export const GET_CONTEST_TYPE = "adminapi/season/get_contest_types";
export const GET_PRIZE_TEMPLATES = "adminapi/season/get_prize_distribution_templates";
export const UPDATE_PRIZE_TEMPLATES = "adminapi/season/add_prize_distribution_template";
export const CANCEL_SIMULATOR_CONTEST_BYID = "adminapi/season/cancel_simulator_contest";
export const GET_SIMULATOR_MATCH_PLAYER = "adminapi/season/get_simulator_match_players";
export const UPDATE_SIMULATOR_PLAYER = "adminapi/season/update_dreameleven_player_name";
export const DELETE_RESULT_PDF = "adminapi/season/delete_result_pdfs";

//Reports
export const EXPORT_REPORT = 'adminapi/index.php/report/export_report';


export const ADMIN_FOLDER_NAME='adminapi/';
export const ADMIN_AUTH_KEY='admin_id_token';

export const baseURL = base_url;
export const ALLOW_COMMUNICATION_DASHBOARD = 1;
export const ALLOW_DEAL = 1;

export const deviceType = "3";
export const deviceID = "";
export const leagueId = "";
export const sportsId = "7";
export const CURRENCY = "₹";

export const successCode = 200;
export const AUTHENTICATE_REQUIRE_CODE = 401;
export const sessionExpireCode = 401;
export const ITEMS_PERPAGE = 50;
export const ITEMS_PERPAGE_LG = 100;
export const CURRENT_PAGE = 1;
export const EXPORT_REPORT_LIMIT = 10000;
export const sessionKey = "";
export const SYSTEM_ERROR = "System generated an error please try again later.";


export var Locale = "";
export class Language {
    static update(data) {
        Locale = data;
    }
}

//Imnages folder dir path
export const  S3 = S3_SERVER_URL;
export const  UPLOAD = 'upload/';
export const  BANNER = 'upload/banner/';
export const  APPBANNER = 'upload/app_banner/';
export const  FLAG = 'upload/flag/';
export const  JERSY = 'upload/jersey/';

export const  PAN = 'upload/pan/';
export const  THUMB = 'upload/profile/thumb/';
export const  BANK = 'upload/bank_document/';
export const  NOIMAGE = 'assets/img/no_image.png';

//Help text here 
export const PROMO_CODE_HELP = "Please enter min 3 and max 100 alphanumeric character for promocode";
export const DISCOUNT_HELP = "Discount to be given to user on entry fee";
export const BENEFIT_CAP_HELP = "Maximum amount value of the discount";
export const PER_USER_ALLOWED_HELP = "This will define that how many times single user can use the same promo code";


export const GET_ALL_SPORTS = "adminapi/common/get_all_sport";
export const GET_SPORT_LEAGUES = "adminapi/common/get_sport_leagues";

export const GET_FILTERS = "adminapi/common/get_filters";
    

//Team Module 
export const GET_ALL_TEAM_LIST  = "adminapi/team/get_all_team_by_sport";
export const UPLOAD_TEAM_JERSEY = "adminapi/team/do_upload/jersey";
export const UPLOAD_TEAM_FLAG   = "adminapi/team/do_upload/flag";
export const EDIT_TEAM_INFO     = "adminapi/team/edit_team_details";

export const GET_ALL_TEAMS_FILTERS    = "adminapi/team/get_all_teams";
export const GET_PLAYERS_LIST    = "adminapi/team/get_players_list";
export const GET_PLAYERS_DETAIL   = "adminapi/team/get_player_detail";
export const UPDATE_PLAYERS_DETAIL   = "adminapi/team/update_player_detail";
//League Module 
export const GET_ALL_LEAGUE_LIST = "adminapi/league/get_sport_leagues";
export const GET_TOURNAMENT_TYPES = "adminapi/league/get_tournament_types";
export const GET_SPORT_LEAGUES_ALL = "adminapi/league/get_sport_leagues";
export const UPDATE_DIFFICULTY_LEVEL = "adminapi/league/update_difficulty_level";
export const GET_ALL_LEAGUE_FILTERS = "adminapi/league/get_all_leagues";


//Fixture Module
export const GET_ALL_FIXTURE                = "adminapi/season/get_all_season_schedule";
export const GET_SEASON_TEAMS_AND_ROSTERS   = "adminapi/season/get_season_teams_and_rosters";
export const UPDATE_SEASON_PLAYER_SALARY    = "adminapi/season/update_season_roster_salary";
export const PUBLISH_SEASON                 = "adminapi/season/publish_season";
export const GET_SEASON_DETAILS             = "adminapi/season/get_season_details";

//Dashboard Module
export const GET_DASHBOARD_SUMMARY = "adminapi/dashboard/get_summary"; 
export const GET_DASHBOARD_TIMELINES = "adminapi/dashboard/get_timelines"; 
export const GET_DASHBOARD_FREEPAID_USERS = "adminapi/dashboard/get_freepaidusers"; 
export const GET_DASHBOARD_DEVICES = "adminapi/dashboard/get_devices"; 
export const GET_DASHBOARD_SITERAKE = "adminapi/dashboard/get_siterake"; 
export const GET_DASHBOARD_LEADERBOARD = "adminapi/dashboard/get_leaderboard"; 
export const GET_DASHBOARD_REFERRAL = "adminapi/dashboard/get_referral"; 
export const GET_CALCULATED_SUMMARY = "adminapi/dashboard/get_calculated_summary"; 
export const GET_DASHBOARD_SEGREGATION = "adminapi/dashboard/get_segregation"; 
export const GET_DASHBOARD_ACTIVE_USERS = "adminapi/dashboard/get_active_users"; 


//contest template
export const GET_CONTEST_TEMPLATE_MASTER_DATA = "adminapi/contest/contest_template/get_all_master_data";
export const GET_CONTEST_TEMPLATE_LIST = "adminapi/contest/contest_template/get_contest_template_list";
export const CREATE_CONTEST_TEMPLATE = "adminapi/contest/contest_template/create_template";
export const DELETE_CONTEST_TEMPLATE = "adminapi/contest/contest_template/delete_template";
export const APPLY_CONTEST_TEMPLATE_TO_LEAGUE = "adminapi/contest/contest_template/apply_template_to_league";
export const GET_FIXTURE_TEMPLATE = "adminapi/contest/contest_template/get_fixture_template";

//contest section
export const CREATE_TEMPLATE_CONTEST = "adminapi/contest/contest/create_template_contest";
export const GET_FIXTURE_CONTEST = "adminapi/contest/contest/get_fixture_contest";
export const CREATE_CONTEST = "adminapi/contest/contest/create_contest";
export const MARK_PIN_CONTEST = "adminapi/contest/contest/mark_pin_contest";
export const GET_CONTEST_FILTER = "adminapi/contest/contest/get_conntest_filter";
export const GET_CONTEST_LIST = "adminapi/contest/contest/get_contest_list";
export const DELETE_CONTEST = "adminapi/contest/contest/delete_contest";
export const REMOVE_CONTEST_RECURRENCE = "adminapi/contest/contest/remove_contest_recurrence";

//user module 
export const GET_USERLIST = "adminapi/user/users";
export const CHANGE_USER_STATUS = "adminapi/user/change_user_status";
export const GET_USER_DETAIL = "adminapi/user/get_user_detail";
export const GET_USER_BASIC = "adminapi/user/get_user_basic";
export const VERIFY_USER_PANCARD = "adminapi/user/verify_user_pancard";
export const GET_USER_BANK_DATA = "adminapi/user/get_user_bank_data";
export const UPDATE_WALLET = "adminapi/user/add_user_balance";
export const GET_USER_TRANSACTION_HISTORY = "adminapi/user/user_transaction_history";


//Scoring
export const GET_SCORING_FILTERS = 'adminapi/scoring/get_scoring_filters'
export const GET_SCORING_RULES = 'adminapi/scoring/get_scoring_rules'
export const UPDATE_MASTER_SCORING_POINTS = 'adminapi/scoring/update_master_scoring_points'

//CMS
export const GET_BANNERS = 'adminapi/banner/get_banners';
export const GET_BANNER_TYPE = 'adminapi/banner/get_banner_type';
export const CREATE_BANNER = 'adminapi/banner/create_banner';
export const GET_APP_BANNERS = 'adminapi/app_banner/get_app_banners';
export const ADD_APP_BANNER = 'adminapi/app_banner/add_app_banner';
export const UPDATE_STATUS = 'adminapi/app_banner/update_status';
export const DELETE_APP_BANNER = 'adminapi/app_banner/delete_app_banner';
export const GET_PAGES = 'adminapi/page/pages/';
export const GET_PAGE_DETAIL = 'adminapi/page/get_page_detail';
export const UPDATE_PAGE = 'adminapi/page/update_page';
export const FRONT_BG_UPLOAD = 'adminapi/setting/front_bg_upload';
export const RESET_FRONT_BG_IMAGE = 'adminapi/setting/reset_front_bg_image';
export const GET_FRONT_BG_IMAGE = 'adminapi/setting/get_front_bg_image';
export const GET_ALL_UPCOMING_COLLECTIONS = 'adminapi/contest/get_all_upcoming_collections';
export const LOBBY_IMAGE_UPLOAD = 'adminapi/banner/do_upload';
export const DELETE_LOBBY_BANNER = 'adminapi/banner/delete_banner';
export const LOBBY_UPDATE_STATUS = 'adminapi/banner/update_status';
export const APP_BANNER_UPLOAD = 'adminapi/app_banner/do_upload';

//User dashboard
export const GET_USER_NOSQL_DATA = 'adminapi/user/get_user_nosql_data';

//User dashboard
export const GET_USER_GAME_HISTORY = 'adminapi/user/user_game_history/';
export const GET_USER_REFERRAL_DATA = 'adminapi/user/get_user_referral_data';
export const GET_LINEUP_DETAIL = 'adminapi/user/get_lineup_detail';
export const GET_GAME_STATS = 'adminapi/contest/get_game_stats';

//Marketing Referral
export const GET_AFFILIATE_MASTER_DATA = 'adminapi/setting/get_affiliate_master_data';
export const UPDATE_AFFILIATE_MASTER_DATA = 'adminapi/setting/update_affiliate_master_data';
export const GET_PROMO_CODES = 'adminapi/promo_code/get_promo_codes';
export const NEW_PROMO_CODE = 'adminapi/promo_code/new_promo_code';
export const CHANGE_PROMO_STATUS = 'adminapi/promo_code/change_promo_status';
export const GET_PROMO_CODE_DETAIL = 'adminapi/promo_code/get_promo_code_detail';

//Finance data
export const GET_WITHDRAWAL_FILTER_DATA = 'adminapi/finance/get_filter_data';
export const GET_ALL_WITHDRAWAL_REQUEST = 'adminapi/finance/get_all_withdrawal_request';
export const UPDATE_WITHDRAWAL_STATUS = 'adminapi/finance/update_withdrawal_status';
export const GET_ALL_TRANSACTION = 'adminapi/finance/get_all_transaction';
export const SEND_EMAIL_SELECTED_USER = 'adminapi/user/send_email_selected_user/';
export const GET_GAME_DETAIL = 'adminapi/contest/get_game_detail';
export const GET_GAME_LINEUP_DETAIL = 'adminapi/contest/get_game_lineup_detail';

export const ADD_NOTE = 'adminapi/user/add_note';
export const GET_NOTES = 'adminapi/user/get_notes';
export const VERIFY_BANK = 'adminapi/user/verify_user_bank';
//Report

export const GET_ALL_USER_REPORT = 'adminapi/report/get_all_user_report';
export const GET_REPORT_MONEY_PAID_BY_USER = 'adminapi/report/get_report_money_paid_by_user';
export const REFERAL_REPORT = 'adminapi/report/referal_report';
export const GET_DEPOSIT_AMOUNT_FILTER_DATA = 'adminapi/report/get_deposit_amount_filter_data';
export const GET_REPORT_USER_DEPOSIT_AMOUNT = 'adminapi/report/get_report_user_deposit_amount';
export const GET_ALL_CONTEST_REPORT = 'adminapi/report/get_all_contest_report';
export const GET_SPORT_LEAGUES_REPORT = "adminapi/contest/get_sport_leagues";
export const GET_ALL_COLLECTIONS_BY_LEAGUE = "adminapi/contest/get_all_collections_by_league";


export const GET_LANGUAGE_LIST = "adminapi/common/common/get_language_list";
export const DO_UPLOAD_LANG = "adminapi/common/common/do_upload_lang";
export const DO_UPLOAD_MASTER_FILE = "adminapi/common/common/do_upload_master_file";

//Announcement
export const BANNER_DO_UPLOAD = "adminapi/banner/do_upload";
export const BANNER_GET_BANNERS = "adminapi/banner/get_banners";
export const BANNER_EDIT_BANNER = "adminapi/banner/edit_banner";
export const BANNER_UPDATE_STATUS = "adminapi/banner/update_status";
export const BANNER_CREATE_BANNER = "adminapi/banner/create_banner";
export const UPDATE_BANNER_CONFIG = "adminapi/banner/update_banner_config";
export const GET_BANNER_CONFIG = "adminapi/banner/get_banner_config";
export const GET_BANNER_POSITIONS = "adminapi/communication/get_banner_positions";

//Notifications
export const DO_UPLOAD_COMMUNICATION = "adminapi/communication/do_upload";
export const GET_IMAGES_COMMUNICATION = "adminapi/communication/get_images";
export const SAVE_COMMUNICATION_IMAGE = "adminapi/communication/save_communication_image";
export const DELETE_IMAGE = "adminapi/communication/delete_image";
export const DELETE_NOTIFICATION = "adminapi/communication/delete_notification";
export const GET_NOTIFICATION_LIST = "adminapi/communication/get_notification_list";
export const SCHEDULE_NOTIFICATION = "adminapi/communication/schedule_notification";

//Get USERS
export const DAHSBOARD_DATA = "adminapi/user/user_stats";
export const DAHSBOARD_STATS = "adminapi/user/user_subscription_stats";
export const GET_ALL_USERS = "adminapi/user/users";
export const GET_USERS_DETAILS = "adminapi/user/get_user_detail";
export const GET_TRANSACTION_LIST = "adminapi/user/get_user_transaction";

//New API Here
export const GET_USERS_COUNTS = "adminapi/user/user_count_stats"; 
export const GET_USERS_GRAPH = "adminapi/user/get_user_graph"; 
export const GET_EXPORT_DATA = "adminapi/user/export_data"; 
export const GET_SUBSCRIPTIONS_LIST = "adminapi/user/get_subscriptions_list";
export const GET_SUBSCRIPTION_COUNTS = "adminapi/user/get_subscription_counts";
export const GET_STATIC_DASHBOARD_COUNTS = "adminapi/user/get_static_dashboard_counts";

export const UPDATE_BANK_AC_DETAIL = "adminapi/user/update_bank_ac_detail";
export const UPDATE_PAN_INFO = "adminapi/user/update_pan_info";
export const UPLOAD_BANK_DOCUMENT = "adminapi/user/upload_bank_document";
export const UPLOAD_PAN = "adminapi/user/upload_pan";
export const GET_ALL_ACTIVE_SPORTS = "adminapi/season/get_all_active_sports";

//historical data
export const LEAGUES_DATA = "adminapi/stats/get_leagues";
export const MATCHES_DATA = "adminapi/stats/get_matches";
export const SCOREBOARD_DATA = "adminapi/stats/get_scorecard";
export const EXPORT_MATCHDATA_CSV = "adminapi/stats/export_csv";
export const GET_SEASON_DETAIL = "adminapi/season/get_season_detail";
export const SAVE_TOSS_DATA = "adminapi/season/save_toss_data";
export const SAVE_WIN_PROBABILITY = "adminapi/season/save_win_probability";
export const TEAM_NEWS_PLAYERS = "adminapi/season/team_news_players";
export const SAVE_TEAM_NEWS = "adminapi/season/save_team_news";
export const REMOVE_TEAM_NEWS_PLAYER = "adminapi/season/remove_team_news_player";

//export team
export const GET_WEBSITE_LIST = "adminapi/exportteam/get_website_list"
export const LOGIN = "adminapi/exportteam/login"
export const VERIFY_OTP = "adminapi/exportteam/verify_otp"
export const GET_UPCOMING_MATCHES = "adminapi/exportteam/get_upcoming_matches"
export const GET_MATCH_PLAYERS = "adminapi/exportteam/get_match_players"
export const MANUAL_PLAYER_MAPPING = "adminapi/exportteam/manual_player_mapping"

//Simulated New team
export const GET_PRACTICE_CONTEST_TEMPLATES = "adminapi/contest/practice_contest/get_practice_contest_templates";
export const CREATE_PRACTICE_CONTEST = "adminapi/contest/practice_contest/create_practice_contest";
export const PRACTICE_CONTEST_DETAILS = "adminapi/contest/practice_contest/practice_contest_details";
export const CANCEL_PRACTICE_CONTEST = "adminapi/contest/practice_contest/cancel_practice_contest";

