import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';
import UserDetails from './views/UserDetails/UserDetails';

function Loading() {
  return <div>Loading...</div>;
}

const DashboardView = Loadable({
   //loader: () => import('./views/DashboardView/DashboardView'),
  loader: () => import('./views/DashboardView/NewDashboard'),
  loading: Loading,
});
const ExpertAdvice = Loadable({
  loader: () => import('./views/ExpertAdvice/ExpertAdvice'),
  loading: Loading,
});

const Announcement = Loadable({
  loader: () => import('./views/Announcement/Announcement'),
  loading: Loading,
});
const ManageUser = Loadable({
  loader: () => import('./views/ManageUser/ManageUser'),
  loading: Loading,
});
const Lineupout = Loadable({
  loader: () => import('./views/Lineupout/Lineupout'),
  loading: Loading,
});
const AddMatchInformation = Loadable({
  loader: () => import('./views/AddMatchInformation/AddMatchInformation'),
  loading: Loading,
});
const Notification = Loadable({
  loader: () => import('./views/Notification/Notification'),
  loading: Loading,
});
const TournamentCategorization = Loadable({
  loader: () => import('./views/TournamentCategorization/TournamentCategorization'),
  loading: Loading,
});
const PlayerAttributes = Loadable({
  loader: () => import('./views/PlayerAttributes/PlayerAttributes'),
  loading: Loading,
});
const CreateNotification = Loadable({
  loader: () => import('./views/Notification/CreateNotification'),
  loading: Loading,
});
const AddImage = Loadable({
  loader: () => import('./views/Notification/ManageImage'),
  loading: Loading,
});
const Logout = Loadable({
  loader: () => import('./views/Pages/Login/Logout'),
  loading: Loading,
});
const SimulatorContest = Loadable({
  loader: () => import('./views/SimulatorContest/SimulatorContest'),
  loading: Loading,
});

const ReferralAmount = Loadable({
  loader: () => import('./views/MarketingReferral/ReferralAmount'),
  loading: Loading,
});

const UserReferralReport = Loadable({
  loader: () => import('./views/Report/UserReferralReport'),
  loading: Loading,
});

const WITHDRAWAL_LIST = Loadable({
  loader: () => import('./views/Finance/WithdrawalList'),
  loading: Loading
})

const TRANSACTION_LIST = Loadable({
  loader: () => import('./views/Finance/TransactionList'),
  loading: Loading
})
const PLATFORM_LOGIN = Loadable({
  loader: () => import('./views/OtherPlatform/PlatformLogin'),
  loading: Loading
})
const PLATFORM_SITE_MAPPING = Loadable({
  loader: () => import('./views/OtherPlatform/PlatformSiteMapping'),
  loading: Loading
})
const LeaguesData = Loadable({
  loader: () => import('./views/HistoricalData/LeaguesData'),
  loading: Loading
})

const MatchesData = Loadable({
  loader: () => import('./views/HistoricalData/MatchesData'),
  loading: Loading
})

const Scorecard = Loadable({
  loader: () => import('./views/HistoricalData/Scorecard'),
  loading: Loading
})

const routes = [
  { path: '/expert_advice', name: 'ExpertAdvice', component: ExpertAdvice },
  { path: '/announcement', name: 'Announcement', component: Announcement },
  { path: '/manage_users', name: 'ManageUser', component: ManageUser },
  { path: '/dashboard', name: 'DashboardView', component: DashboardView },
  { path: '/user_info/:user_unique_id', name: 'UserDetails', component: UserDetails },
  { path: '/lineupout', name: 'Lineupout', component: Lineupout },
  { path: '/add-match-information', name: 'AddMatchInformation', component: AddMatchInformation },
  { path: '/simulator_contest/:tab/:seasonGameUID/:leagueID/:contestId/:fixtureInfo', name: 'Lineupout', component: SimulatorContest },
  { path: '/notification', name: 'Notification', component: Notification },
  { path: '/tournament_categorization',name:'TournamentCategorization',component:TournamentCategorization},
  { path: '/player_attributes' , name: 'PlayerAttributes', component:PlayerAttributes },
  { path: '/create_notification', name: 'Notification', component: CreateNotification },
  { path: '/add_image', name: 'AddImage', component: AddImage },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/referral_amount', name: 'ReferralAmount', component: ReferralAmount },
  { path: '/referral_report', name: 'UserReferralReport', component: UserReferralReport },
  { path: '/historical_data/leagues_data', name: 'LeaguesData', component: LeaguesData },
  { path: '/historical_data/matches_data/:league_id', name: 'MatchesData', component: MatchesData },
  { path: '/historical_data/scorecard/:season_game_uid', name: 'Scorecard', component: Scorecard },
  {
    path: '/finance/withdrawal_list',
    name: "WITHDRAWAL_LIST",
    component: WITHDRAWAL_LIST,
    exact: true
  },
  {
    path: '/finance/transaction_list',
    name: "TRANSACTION_LIST",
    component: TRANSACTION_LIST,
    exact: true
  },
  {
    path: '/other-platform/login',
    name: "PLATFORM_LOGIN",
    component: PLATFORM_LOGIN,
    exact: true
  },
  {
    path: '/other-platform/site-mapping',
    name: "PLATFORM_SITE_MAPPING",
    component: PLATFORM_SITE_MAPPING,
    exact: true
  }
  
  // { path: '/', name: 'Home', component: DefaultLayout, exact: true },
  // { path: '/verify-fantasy-domain/:encoded_auth_key', name: 'Verify Domain', component: VerifyFantasyDomain },
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  // { path: '/contest/createtemplatecontest/:league_id/:season_game_uid', name: 'CreateTemplateContest', component: CreateTemplateContest, exact: true},
  // { path: '/contest/fixturecontest/:league_id/:season_game_uid', name: 'FixtureContest', component: FixtureContest, exact: true},
  // { path: '/contest/createcontest/:league_id/:season_game_uid', name: 'CreateContest', component: CreateContest, exact: true},
];

export default routes;
