import * as NC from "../src/helper/NetworkingConstants";
const items= {
  items: [
    { 
      name: 'Dashboard',
      url: '/dashboard',
    },
    { 
      name: 'Manage Users',
      url: '/manage_users',
    },
    { 
      name: 'Fixtures',
      url: '/lineupout',
    },
    { 
      name: 'Expert Advice',
      url: '/expert_advice',
    },
    { 
      name: 'Announcement',
      url: '/announcement',
    },
    { 
      name: 'Notification',
      url: '/notification',
    },
    { 
      name: 'Tournament',
      url: '/tournament_categorization',
    },
    { 
      name: 'Player Attributes',
      url: '/player_attributes',
    },
    { 
      name: 'Referral Amount',
      url: '/referral_amount',
    },
    { 
      name: 'Referral Report',
      url: '/referral_report',
    },
    { 
      name: 'Historical Data',
      url: '/historical_data/leagues_data',
    },
    // ,{ 
    //   name: 'Manage Finance',
    //   url: '/',
    // }
    
  ]
};
items.items.push({
  name: 'Manage Finance',
  url: '/finance',
  children: [{
      name: 'Withdrawal List',
      url: '/finance/withdrawal_list',
    },
    {
      name: 'Transaction List',
      url: '/finance/transaction_list',
    }
  ],
})
items.items.push({
  name: 'Other Platform',
  url: '/other-platform',
  children: [
    {
      name: 'Login Platform',
      url: '/other-platform/login',
    },
    {
      name: 'Site mapping',
      url: '/other-platform/site-mapping',
    }
  ],
})
export default items;
